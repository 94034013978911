/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { Captcha } from "primereact/captcha";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Plugins*/
import { useTranslation } from "react-i18next";
import i18n from "../../plugins/i18n";

/*Service*/
import { LoginUser } from "../../service/user";

//Strings
import { lngs } from "../../constants/string";

export const Login = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    kullaniciAdi: localStorage.loginUserName,
    Password: localStorage.loginUserPassword,
  });
  const [checked, setChecked] = useState(); //Giriş bilgilerimi hatırla
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recapteToken, setRecapteToken] = useState("");

  useEffect(() => {
    if (localStorage.loginUserInfoCheck === "true") {
      setChecked(true);
    }
  }, []);
  const showResponse = (response) => {
    //Recapte response
    setRecapteToken(response);
  };

  const login = () => {
    setIsLoading(true);
    LoginUser(data.kullaniciAdi, data.Password)
      .then((res) => {
        setIsLoading(false);
        if (checked) {
          localStorage.setItem("loginUserInfoCheck", checked);
          localStorage.setItem("loginUserName", data.kullaniciAdi);
          localStorage.setItem("loginUserPassword", data.Password);
        } else {
          localStorage.setItem("loginUserInfoCheck", "");
          localStorage.setItem("loginUserName", "");
          localStorage.setItem("loginUserPassword", "");
        }
        window.location.reload();

        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Giriş Yapıldı",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const onKeyUpValue = (event) => {
    if (event.keyCode === 13) {
      login();
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: "50%", margin: "15% auto" }}
        >
          <ContentLoading />
        </div>
      ) : (
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: "90%", margin: "15% auto" }}
        >
          <Toast ref={toast} />
          <div className="text-center mb-5">
            <img
              src="assets/layout/images/logo.svg"
              alt="Gamb trade"
              height="50"
              className="mb-3"
              style={{ maxWidth: "100%" }}
            />
            <div className="text-900 text-3xl font-medium mb-3">
              {t("login.title")}
            </div>
            <span className="text-600 font-medium line-height-3">
              {t("login.description")}
            </span>
          </div>

          {Object.keys(lngs).map((lng) => (
            <button
              style={
                i18n.resolvedLanguage === lng
                  ? { display: "none" }
                  : { display: "block" }
              }
              className="p-link login-lang-img-link"
              key={lng}
              onClick={() => i18n.changeLanguage(lng)}
              disabled={i18n.resolvedLanguage === lng}
            >
              {lng === "tr" ? (
                <img
                  style={{ width: 25, margin: 0 }}
                  src={
                    "https://cleandye.com/wp-content/uploads/2020/01/English-icon.png"
                  }
                />
              ) : (
                <img
                  style={{ width: 25, margin: 0 }}
                  src={
                    "https://cdn-icons-png.flaticon.com/512/3909/3909414.png"
                  }
                />
              )}
            </button>
          ))}
          <div>
            <label htmlFor="email1" className="block text-900 font-medium mb-2">
              {t("login.userName")}
            </label>
            <InputText
              id="email1"
              type="text"
              className="w-full mb-3"
              value={data.kullaniciAdi}
              onChange={(e) =>
                setData({ ...data, kullaniciAdi: e.target.value })
              }
            />

            <label
              htmlFor="password1"
              className="block text-900 font-medium mb-2"
            >
              {t("login.password")}
            </label>
            <InputText
              onKeyUp={onKeyUpValue.bind(this)}
              id="password1"
              type="password"
              className="w-full mb-3"
              value={data.Password}
              onChange={(e) => setData({ ...data, Password: e.target.value })}
            />
            <Captcha
              siteKey="6LcJshshAAAAAJ8plEP5gWOM49-EfiHYkB3LVx46"
              onResponse={showResponse}
            ></Captcha>

            <div className="flex align-items-center justify-content-between mb-3 mt-3">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="rememberme1"
                  binary
                  className="mr-2"
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                />
                <label htmlFor="rememberme1">{t("login.beniHatirla")}</label>
              </div>
            </div>

            <Button
              label={t("login.button")}
              icon="pi pi-user"
              onClick={login}
              style={{ width: "100%" }}
              className={
                "w-full" + data.kullaniciAdi && data.Password && recapteToken
                  ? " "
                  : "p-disabled"
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Login, comparisonFn);
